import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"drawer-category-name"},[_vm._v("ホーム")]),_c(VCard,{staticClass:"pa-5"},[_c('ul-content-header',{attrs:{"heading-main":_vm.headingMain,"heading-sub":_vm.headingSub}}),_c(VRow,[_c(VCol,{staticClass:"grow"},[_c('ul-breadcrumbs',{attrs:{"items":_vm.breadCrumbs}})],1)],1),_c('div',[_vm._v("お知らせはありません。")]),_c(VSpacer,{staticClass:"ma-10"}),_c('h2',[_vm._v("お問い合わせ")]),_c(VDivider,{staticClass:"mt-2 mb-2"}),(_vm.contactCorporateName)?_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.contactCorporateName)+" ")])],1):_vm._e(),(_vm.contactReceptionHours)?_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-0"},[_vm._v(" 受付時間 : "+_vm._s(_vm.contactReceptionHours)+" ")])],1):_vm._e(),_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-0"},[_vm._v(" MAIL : "+_vm._s(_vm.contactEmailFront)+" ")])],1),_c(VRow,[(!_vm.isUsenStore())?_c(VCol,{staticClass:"pt-0 pb-0"},[_vm._v(" LINE ID : @"+_vm._s(_vm.lineId)+" ")]):_vm._e()],1),(_vm.contactPhoneNumber)?_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"shrink pt-0 pb-0 pr-0"},[_c('img',{staticStyle:{"width":"30px","float":"left"},attrs:{"src":require("../../assets/free-dial.png"),"alt":"logo"}})]),_c(VCol,{staticClass:"pt-0 pb-0 pl-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.contactPhoneNumber)+" ")])],1):_vm._e(),_c(VRow,[(!_vm.isUsenStore())?_c(VCol,{staticClass:"pt-0 pb-0"},[_c('a',{attrs:{"href":_vm.lineFriendLink}},[_c('img',{attrs:{"src":"https://scdn.line-apps.com/n/line_add_friends/btn/ja.png","alt":"友だち追加","height":"24","border":"0"}})])]):_vm._e()],1),_c(VRow,[(!_vm.isAdminTool())?_c(VCol,{staticClass:"text-center"},[_c(VBtn,{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":_vm.mailTo}},[_vm._v(" メールで質問する ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }