import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({
  components: { UlContentHeader, UlBreadcrumbs }
})
export default class Index extends Vue {
  businessCode = null as string | null;
  contactPhoneNumber = null as string | null;
  contactCorporateName = null as string | null;
  contactReceptionHours = null as string | null;
  contactEmailFront = null as string | null;
  lineId = null as string | null;
  lineFriendLink = null as string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "お知らせ";
  headingSub = "Information";
  breadCrumbs = [
    { text: "ホーム", disabled: false, to: { name: "home" } },
    { text: "お知らせ", disabled: true }
  ];

  async created(){
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessCode = storage.code ? storage.code : null;
    this.contactCorporateName = storage.contactCorporateName ? storage.contactCorporateName : null;
    this.contactReceptionHours = storage.contactReceptionHours ? storage.contactReceptionHours  : null;
    this.contactPhoneNumber = storage.contactPhoneNumber ? storage.contactPhoneNumber : null;
    this.contactEmailFront =  storage.contactEmailFront ? storage.contactEmailFront : null;
    this.lineId = storage.lineId ? storage.lineId : null;
    this.lineFriendLink = "https://line.me/R/ti/p/@" + this.lineId;
  }

  mailTo() {
    location.href =
      "mailto:" + this.contactEmailFront + "?subject=お問い合わせ&body=ご連絡時には以下の情報をご記載ください。%0d%0a=====%0d%0a■店舗名：%0d%0a■ご担当者様名：%0d%0a■ご相談・ご依頼内容：%0d%0a=====%0d%0a";
  }

  isAdminTool() {
    let userAgent = window.navigator.userAgent;
    if (userAgent.match(/newup_admin_tool_ios/) || userAgent.match(/newup_admin_tool_android/)) {
      return true;
    }

    return false;
  }

  isUsenStore() {
    if (this.businessCode === "usen") {
      return true;
    }

    return false;
  }
 
}
